import React, { useEffect, useState } from "react";

import { About, Footer, Header, Skills, Testimonial, Work } from "./container";
import { Navbar, NavigationDots, SocialMedia } from "./components";
import "./App.scss";

const App = () => {
	const sections = [
		{ id: "home", label: "Home" },
		{ id: "about", label: "About" },
		{ id: "work", label: "Work" },
		{ id: "skills", label: "Skills" },
		{ id: "testimonial", label: "Testimonial" },
		{ id: "contact", label: "Contact" },
	];

	const sectionRefs = sections.reduce(
		(acc, section) => ({ ...acc, [section.id]: { current: null } }),
		{}
	);

	const sectionComponents = {
		home: Header,
		about: About,
		work: Work,
		skills: Skills,
		testimonial: Testimonial,
		contact: Footer,
	};

	const [activeSection, setActiveSection] = useState(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						setActiveSection(entry.target.id);
					}
				});
			},
			{ threshold: 0.5 }
		);

		Object.values(sectionRefs).forEach((ref) => {
			if (ref.current) observer.observe(ref.current);
		});

		return () => {
			Object.values(sectionRefs).forEach((ref) => {
				if (ref.current) observer.unobserve(ref.current);
			});
		};
	}, [sectionRefs]);

	const handleClick = (id) => {
		const element = document.getElementById(id);
		if (element) {
			element.scrollIntoView({ behavior: "smooth", block: "start" });
		}
	};

	return (
		<div className="app">
			{activeSection !== "home" && <SocialMedia />}
			<Navbar
				sections={sections}
				activeSection={activeSection}
				handleClick={handleClick}
			/>
			{sections.map(({ id }) => {
				const Component = sectionComponents[id];
				if (!Component) {
					console.error(`Component for ${id} not found`);
					return null;
				}
				return <Component key={id} id={id} ref={sectionRefs[id]} />;
			})}
			<div className="copyright">
				<p>@2024 Omkar</p>
				<p>All rights reserved</p>
			</div>
			<NavigationDots
				sections={sections}
				activeSection={activeSection}
				handleClick={handleClick}
			/>
		</div>
	);
};

export default App;
