import React, { useState } from "react";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { motion } from "framer-motion";

import { images } from "../../constants";
import "./Navbar.scss";

const Navbar = ({ sections, activeSection, handleClick }) => {
	const [toggle, setToggle] = useState(false);

	console.log(toggle);

	return (
		<nav className="app__navbar">
			<div
				className="app__navbar-logo"
				onClick={() => handleClick(sections[0])}
			>
				<img src={images.logo} alt="logo" />
			</div>
			<ul className="app__navbar-links">
				{sections.map(({ id, label }) => (
					<li
						className={`app__flex p-text ${
							activeSection === id ? "active" : ""
						}`}
						key={`link-${id}`}
						onClick={() => handleClick(id)}
					>
						<div />
						<span>{label}</span>
					</li>
				))}
			</ul>

			<div className="app__navbar-menu">
				<HiMenuAlt4 onClick={() => setToggle(true)} />

				{toggle && (
					<motion.div
						whileInView={{ x: [100, 0], opacity: [0.9, 1] }}
						style={{ opacity: 0 }}
						transition={{ duration: 0.3, ease: "easeOut" }}
					>
						<HiX onClick={() => setToggle(false)} />
						<ul>
							{sections.map(({ id, label }) => (
								<li className={activeSection === id ? "active" : ""} key={id}>
									<span
										onClick={() => {
											setToggle(false);
											handleClick(id);
										}}
									>
										{label}
									</span>
								</li>
							))}
						</ul>
					</motion.div>
				)}
			</div>
		</nav>
	);
};

export default Navbar;
