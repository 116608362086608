import React from "react";
import { BsTwitter, BsInstagram, BsGithub } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";

const SocialMedia = () => (
	<div className="app__social">
		<div
			className="btnGH"
			onClick={() => window.open("https://github.com/OmkarKandale", "_blank")}
		>
			<BsGithub />
		</div>
		<div
			className="btnT"
			onClick={() => window.open("https://x.com/OmkarKandale1", "_blank")}
		>
			<BsTwitter />
		</div>
		<div
			className="btnF"
			onClick={() => window.open("https://www.facebook.com/okandale", "_blank")}
		>
			<FaFacebookF />
		</div>
		<div
			className="btnI"
			onClick={() =>
				window.open("https://www.instagram.com/omkar_kandale", "_blank")
			}
		>
			<BsInstagram />
		</div>
	</div>
);

export default SocialMedia;
